<template>
  <div>
    <!-- scroll-target="#scrolling-techniques-7" -->
    <v-app-bar
      :dense="$vuetify.breakpoint.mobile"
      color="blue lighten-5"
      class="ma-0"
    >
      <v-icon>mdi-application</v-icon>
      <v-toolbar-title class="ml-3"
        >Versão do sistema: {{ appVersion }}</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-btn
        elevation="2"
        :icon="$vuetify.breakpoint.mobile"
        :small="$vuetify.breakpoint.mobile"
        color="secondary"
        class="pa-2 ml-2"
        @click="setMenuMobile"
        v-tooltip.bottom-end="'Menu geral do sistema'"
      >
        {{ msgButton("Menu") }}
        <v-icon v-if="!$store.state.isMenuVisibleMobile">mdi-menu-down </v-icon>
        <v-icon v-else> mdi-menu </v-icon>
      </v-btn>
    </v-app-bar>
    <v-row class="pa-3">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="2">
        <v-card class="pa-2" outlined height="100%">
          <v-row class="pa-3" justify="center">
            <v-col cols="12" sm="12" align="center">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6"
                    >Desenvolvido por:</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <!-- <v-card class="pa-2" >
                <v-card-title class="text-h6">
              Desenvolvido por :
            </v-card-title>

              </v-card> -->
            </v-col>
            <v-col class="pa-3" cols="12" sm="12" align="center">
              <v-img src="@/assets/logo_logicbox.png" width="200" alt="200" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="pa-0" outlined height="100%">
          <v-row class="pa-0">
            <v-col cols="12" sm="6" height="100%">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h8 mb-1">
                    Sistema Logidoc Dashboard Web
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-weight-medium">
                    Endereço: Av. Iguaçu 41 - Conjunto 404 <br />
                    Bairro: Petrópolis - Cidade: Porto Alegre <br />
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="font-weight-medium">
                    UF: RS - Cep: 90470-430 <br />
                    Fone: 51-3076-3311
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!-- <v-card class="pa-2" >
                <v-card-title class="text-h6">
              Desenvolvido por :
            </v-card-title>

              </v-card> -->
            </v-col>
            <v-col cols="12" sm="6">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h8 mb-1">
                    Contato:
                    <a href="mailto:contato@logicbox.com.br"
                      >contato@logicbox.com.br</a
                    >
                  </v-list-item-title>
                  <v-list-item-title class="text-h8 mb-1">
                    Site:
                    <a href="https://www.logicbox.com.br"
                      >www.logicbox.com.br</a
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-btn
                elevation="2"
                class="pa-2 ml-2"
                align="center"
                color="#FFFFFF"
                @click="mostraHistoricoVersao = true"
                v-tooltip.bottom-end="'Mostra o historico de alterações'"
                >Histórico das Versões
                <v-icon>mdi-list-status</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-dialog v-model="mostraHistoricoVersao" fullscreen>
      <v-card class="pa-3">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Histórico das versões</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="pa-3">
            <v-btn class="pa-3" icon dark @click="mostraHistoricoVersao = false"
              >Fechar
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-rows>
          <v-col cols="12" sm="2" lg="2">
            <v-card elevation="2" outlined>
              <v-card-title>Legenda alterações </v-card-title>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width="1%">Legenda</th>
                      <th class="text-left">Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in jsonListVersion.typeImplementation"
                      :key="item.type"
                    >
                      <td>
                        <v-chip small :color="getColor(item.type)" dark>
                          {{ item.type }}
                        </v-chip>
                      </td>

                      <td>{{ item.description }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-rows>
        <v-rows>
          <v-col cols="12" sm="2" lg="6">
            <v-card elevation="2" outlined>
              <v-card-title>Histórico </v-card-title>
              <v-simple-table class="pa-1" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Versão</th>
                      <th class="text-left">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in jsonListVersion.versionList"
                      :key="item.version"
                    >
                      <td>
                        <b>{{ item.version }}</b>
                      </td>
                      <td>
                        <b>{{ item.date }}</b>
                      </td>
                      <v-card elevation="2" outlined>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left" width="1%">Tipo</th>
                                <th class="text-left">Descrição</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in item.implementation"
                                :key="item.type"
                              >
                                <td>
                                  <v-chip
                                    small
                                    :color="getColor(item.type)"
                                    dark
                                  >
                                    {{ item.type }}
                                  </v-chip>
                                </td>
                                <td>{{ item.description }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-rows>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapState } from "vuex";
import { version } from "@/../package.json";
import jsonVersion from "@/version_list.json";

export default {
  name: "Sobre",
  computed: {
    ...mapState(["user", "isMenuVisible"]),
    heightScroll() {
      if (this.$vuetify.breakpoint.mobile) {
        return "height: 600px;";
      } else {
        return "height: 900px;";
      }
    },
  },
  data: function () {
    return {
      mode: "viewer",
      appVersion: version,
      mostraHistoricoVersao: false,
      jsonListVersion: jsonVersion,
      itemsPerPage: 1,
    };
  },
  methods: {
    getColor(type) {
      if (type == "-" || type == "(-)") return "red";
      else if (type == "+" || type == "(+)") return "green";
      else if (type == "%" || type == "(%)") return "orage";
      else return "blue";
    },
    setMenuMobile() {
      if (this.$store.state.isMenuVisibleMobile) {
        this.$store.state.isMenuVisibleMobile = false;
      } else {
        this.$store.state.isMenuVisibleMobile = true;
      }
    },
    msgButton: function (value) {
      if (this.$vuetify.breakpoint.mobile) {
        return "";
      } else {
        return value;
      }
    },
  },
  mounted() {
    this.$store.state.isMenuVisibleMobile = true;
  },
};
</script>

<style >
.texto .v-label {
  font-size: 1.3em;
}

.filtroTexto input {
  text-transform: uppercase;
}
.text-field-transparent .v-input__slot {
  background: transparent !important;
}
</style>